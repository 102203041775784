import React from "react";
import PT from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { MenuItem } from "../menu-items";
import { Arrow } from "../arrow-icon";
import "./styles.m.css";

class ParentMenuItemBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSubMenu: false
    };
    this.toggleSubMenu = this.toggleSubMenu.bind(this);
  }
  toggleSubMenu() {
    this.setState(function(prevState) {
      return {
        openSubMenu: !prevState.openSubMenu
      };
    });
  }
  render() {
    const menuItemStyele = this.props.publisherAttributes.lang === "ur" ? "ur-menu-item" : "en-menu-item";
    const closeMenu = this.props.closeMenu;
    return (
      <li styleName={`menu-item ${menuItemStyele}`}>
        {getParentMenuItem(this.props.item, this.toggleSubMenu, this.state.openSubMenu, closeMenu)}
        <ul styleName={`submenu ${this.state.openSubMenu ? "submenu--show" : ""}`}>
          {this.props.item.children.map(child => {
            return getSubmenuItem(child, closeMenu);
          })}
        </ul>
      </li>
    );
  }
}

ParentMenuItemBase.propTypes = {
  item: PT.object,
  publisherAttributes: PT.object,
  closeMenu: PT.func
};

function getSubmenuItem(child, closeMenu) {
  return (
    <li key={child["id"]} styleName="menu-item menu-item--small ">
      <MenuItem item={child} haveColor closeMenu={closeMenu} />
    </li>
  );
}

function getParentMenuItem(item, toggleSubMenu, openSubMenu, closeMenu) {
  return (
    <div
      styleName={`menu-item__parent ${openSubMenu ? "menu-item__parent--submenu-open" : ""}`}
      onClick={toggleSubMenu}
    >
      <MenuItem item={item} key={item["id"]} closeMenu={closeMenu} />
      <Arrow isOpen={openSubMenu} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"])
  };
}

export const ParentMenuItem = connect(
  mapStateToProps,
  null
)(ParentMenuItemBase);
