import React from "react";
import { oneOf, string, func } from "prop-types";

import FacebookIcon from "../icons/facebook";

import "./social-auth-button.m.css";

const SocialAuthButton = ({ type, className, href, onSocialClick }) => (
  <a
    styleName={`${type}-wrapper`}
    target="_blank"
    className={className}
    href={href}
    onClick={onSocialClick}
    rel="noreferrer"
  >
    {type === "fb" ? <FacebookIcon /> : <div styleName="google-icon" />}
    <div styleName="title-wrapper">
      <span styleName="title">{type === "fb" ? "FACEBOOK" : "GOOGLE"}</span>
    </div>
  </a>
);

SocialAuthButton.propTypes = {
  href: string,
  type: oneOf(["google", "fb"]),
  className: string,
  onSocialClick: func
};

export default SocialAuthButton;
