import React from "react";
import YoutubeIcon from "../youtube-icon";

import "./section-link.m.css";

const VideoSectionLink = () => {
  return (
    <a styleName="section-link" href="/section/videos">
      <YoutubeIcon width={20} height={20} />
      <span styleName="text"> Videos</span>
    </a>
  );
};

export default VideoSectionLink;
