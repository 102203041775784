import React from "react";
import PropTypes from "prop-types";
export function Youtube({ color = "#ffffff", width = 21, height = 21 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        fillRule="evenodd"
        d="M21.209 7.31c.164.632.28 1.593.351 2.882l.035 1.758-.035 1.758c-.07 1.312-.187 2.285-.351 2.918-.118.398-.329.75-.633 1.054a2.437 2.437 0 0 1-1.09.633c-.61.164-2.04.281-4.29.352l-3.198.035-3.2-.035c-2.25-.07-3.68-.188-4.289-.352a2.437 2.437 0 0 1-1.09-.633 2.452 2.452 0 0 1-.632-1.054c-.164-.633-.282-1.606-.352-2.918L2.4 11.95c0-.516.012-1.102.035-1.758.07-1.289.188-2.25.352-2.883.117-.422.328-.785.633-1.09a2.437 2.437 0 0 1 1.09-.632c.609-.164 2.038-.282 4.288-.352l3.2-.035 3.199.035c2.25.07 3.68.188 4.289.352.422.117.785.328 1.09.633.304.304.515.667.633 1.09zm-11.18 7.523l5.027-2.883-5.027-2.848v5.73z"
      />
    </svg>
  );
}
Youtube.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};
