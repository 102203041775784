/* eslint-disable global-require */
import { startApp } from "@quintype/framework/client/start";
import { renderApplication, preRenderApplication } from "./render";
import "../../app/assets/stylesheets/app.scss";
import wretch from "wretch";
import CustomReducers from "../isomorphic/components/helper/reducers";

function enableHotReload(store) {
  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./render", () => renderApplication(store));
  }
}

window.addEventListener("load", () => {
  const id = setTimeout(() => {
    if (
      !window.fetch ||
      !window.Array.prototype.map ||
      !window.Promise ||
      !window.URLSearchParams ||
      !window.IntersectionObserver ||
      !window.Set
    ) {
      const t = document.createElement("script");
      t.type = "text/javascript";
      t.defer = true;
      t.src = "https://cdn.polyfill.io/v2/polyfill.min.js?features=default,IntersectionObserver,fetch";
      document.getElementsByTagName("head")[0].appendChild(t);
    }

    const s1 = document.createElement("script");
    s1["data-ad-client"] = "ca-pub-9709396780967432";
    s1.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    s1.defer = true;
    document.getElementsByTagName("head")[0].appendChild(s1);

    const gumletScript = document.createElement("script");
    gumletScript.defer = true;
    gumletScript.src = "https://cdn.gumlet.com/gumlet.js/2.0/gumlet.min.js";
    document.body.appendChild(gumletScript);

    clearTimeout(id);
  }, 4750);
});

global.wretch = wretch;
global.width = window.innerWidth;

startApp(renderApplication, CustomReducers, {
  preRenderApplication,
  enableServiceWorker: process.env.NODE_ENV === "production",
  appVersion: require("../isomorphic/app-version")
}).then(enableHotReload);
