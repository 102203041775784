import React from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { withGoogleLogin } from "@quintype/bridgekeeper-js";

import SocialAuthButton from "../social-auth-button";

const GoogleAuth = () => {
  const publisherAttributes = useSelector(state => get(state, ["qt", "config", "publisher-attributes"], {}));
  const redirectUrl = window ? window.location.href : "";
  const appId = get(publisherAttributes, ["socialAuth", "google"], "");

  const { serverSideLoginPath } = withGoogleLogin({
    scope: "email",
    emailMandatory: true,
    appId: appId,
    redirectUrl: encodeURIComponent(redirectUrl)
  });

  return <SocialAuthButton type="google" href={serverSideLoginPath} />;
};

export default GoogleAuth;
