import React from "react";
import PT from "prop-types";
import { ParentMenuItem } from "../parent-menu-item";
import "./styles.m.css";
import { Link } from "@quintype/components";
import { AppLogo } from "../../app-logo";
import { CloseIcon } from "../../../basic/icons/close-icon";

class MenuItems extends React.Component {
  render() {
    return (
      <div styleName="menu-wrapper">
        <div styleName="logo" key="logo">
          <AppLogo color="#fff" />
          <CloseIcon onClick={this.props.closeMenu} />
        </div>
        <ul styleName="navbar-menu" key="menu">
          {this.props.items.slice(0, 8).map((item, index) => {
            if (item.children.length) {
              return <ParentMenuItem item={item} key={`${item["id"]}${index}`} closeMenu={this.props.closeMenu} />;
            }
            return (
              <li key={`${item["id"]}${index}`} styleName="menu-item">
                <MenuItem item={item} closeMenu={this.props.closeMenu} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

MenuItems.propTypes = {
  closeMenu: PT.func,
  items: PT.array
};

function MenuItem(props) {
  // TODO: Add submenu support
  return props.item.isExternalLink ? (
    <a href={props.item.completeUrl} target="_blank" rel="noopener noreferrer">
      <div
        style={{
          color: props.item.title === "States 360°" ? "#ff0000" : "",
          backgroundColor: props.item.title === "States 360°" ? "#ccc" : "",
          padding: props.item.title === "States 360°" ? "0px 5px" : "",
          borderRadius: props.item.title === "States 360°" ? "5px" : ""
        }}
        onClick={props.closeMenu}
      >
        {props.item.title}
      </div>
    </a>
  ) : (
    <Link href={props.item.completeUrl}>
      <div
        style={{
          color: props.item.title === "States 360°" ? "#ff0000" : "",
          backgroundColor: props.item.title === "States 360°" ? "#ccc" : "",
          padding: props.item.title === "States 360°" ? "7px 15px" : "",
          borderRadius: props.item.title === "States 360°" ? "5px" : ""
        }}
        onClick={props.closeMenu}
      >
        {props.item.title}
      </div>
    </Link>
  );
}

MenuItem.propTypes = {
  item: PT.shape({
    isExternalLink: PT.bool,
    completeUrl: PT.string,
    title: PT.string,
    data: PT.object
  }),
  closeMenu: PT.func
};

export { MenuItems, MenuItem };
