import React, { useRef, useState } from "react";
import { func, bool, string } from "prop-types";

import EmailIcon from "../../atoms/icons/EmailIcon";
import LineLeftArrow from "../../atoms/icons/line-left-arrow";
import KeyIcon from "../../atoms/icons/KeyIcon";

import { forgotPassword, resetPassword } from "../../utils/api";

import "./forgot-password.m.css";

const ForgotPasswordView = ({ toggleForgotPasswordView, fromResetPassword = false, token = "" }) => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [sentVerification, setSentVerification] = useState(false);
  const [errorMsg, setError] = useState("");

  async function handleForgotPassword(e) {
    e.preventDefault();

    forgotPassword(emailRef.current.value)
      .then(msg => {
        showFormAgain();
      })
      .catch(e => console.log("handleForgotPassword error", e));
  }

  const handleResetPassword = e => {
    e.preventDefault();
    const newPassword = passwordRef.current.value;
    const confirmNewPassword = confirmPasswordRef.current.value;
    if (newPassword !== confirmNewPassword) {
      console.log("Password doesn't match");
      setError("Password doesn't match");
      return;
    }
    resetPassword(token, passwordRef.current.value, confirmPasswordRef.current.value)
      .then(item => {
        window.location.href = "/";
      })
      .catch(e => console.log("reset password failed", e));
  };

  function showFormAgain() {
    setSentVerification(!sentVerification);
  }

  const RenderBodyView = () => {
    if (fromResetPassword) {
      return (
        <React.Fragment>
          <p styleName="title">Enter your new password</p>
          <form onSubmit={handleResetPassword}>
            <div styleName="box-wrapper">
              <KeyIcon />
              <input
                type="password"
                onFocus={() => setError("")}
                styleName="input-fields"
                ref={passwordRef}
                placeholder="Enter Password"
                required
              />
            </div>
            <div styleName="box-wrapper">
              <KeyIcon />
              <input
                type="password"
                styleName="input-fields"
                ref={confirmPasswordRef}
                placeholder="Re-enter Password"
                required
                onFocus={() => setError("")}
              />
            </div>
            {errorMsg && <p styleName="error">{errorMsg}</p>}
            <div>
              <input type="submit" styleName="send-btn" value="Send" />
            </div>
          </form>
        </React.Fragment>
      );
    } else if (!sentVerification) {
      return (
        <React.Fragment>
          <p styleName="title">Enter your email to receive a link to reset your password</p>
          <form onSubmit={handleForgotPassword}>
            <div styleName="box-wrapper">
              <EmailIcon />
              <input type="email" styleName="input-fields" ref={emailRef} placeholder="Enter email" required />
            </div>
            <div>
              <input type="submit" styleName="send-btn" value="Send" />
            </div>
          </form>
          <b styleName="to-login" onClick={toggleForgotPasswordView}>
            <LineLeftArrow />
            Back to Login
          </b>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p styleName="title">A Message With Further Instructions Has Been Sent To Your Email Address.</p>
          <center styleName="bottom-text">
            <b styleName="contact-us" onClick={showFormAgain}>
              Change Email Address
            </b>
          </center>
        </React.Fragment>
      );
    }
  };

  return (
    <div styleName="wrapper">
      <div styleName={`flex-col-spaced ${sentVerification ? "center" : ""}`}>{RenderBodyView()}</div>
    </div>
  );
};

ForgotPasswordView.propTypes = {
  toggleForgotPasswordView: func,
  fromResetPassword: bool,
  token: string
};

export default ForgotPasswordView;
