import { PAGE_TYPE } from "./constants";
import { pickComponentHelper } from "@quintype/framework/server/pick-component-helper";

const { pickComponent, getChunkName } = pickComponentHelper(
  {
    [PAGE_TYPE.HOME_PAGE]: { chunk: "list", component: "HomePage" },
    [PAGE_TYPE.SECTION_PAGE]: { chunk: "list", component: "SectionAndCollectionPage" },
    [PAGE_TYPE.TAG_PAGE]: { chunk: "list", component: "TagPage" },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: "list", component: "SearchPage" },
    [PAGE_TYPE.STORY_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.STORY_PREVIEW]: { chunk: "story", component: "StoryPagePreview" },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: "list", component: "AuthorPage" },
    [PAGE_TYPE.HOME_PREVIEW]: { chunk: "list", component: "HomePagePreview" },
    [PAGE_TYPE.CONTACT_PAGE]: { chunk: "contribute", component: "ContactPage" },
    [PAGE_TYPE.DASHBOARD_PAGE]: { chunk: "contribute", component: "DashboardPage" },
    [PAGE_TYPE.CONTRIBUTE_PAGE]: { chunk: "contribute", component: "ContributePage" },
    [PAGE_TYPE.SUBSCRIBE_PAGE]: { chunk: "contribute", component: "SubscribePage" },
    [PAGE_TYPE.SUBSCRIPTION_PAGE]: { chunk: "contribute", component: "SubscribePage" },
    [PAGE_TYPE.SUBSCRIBE_NP_PAGE]: { chunk: "contribute", component: "SubscribeNpPage" },
    [PAGE_TYPE.NEWSLETTER_PAGE]: { chunk: "contribute", component: "NewsletterPage" },
    [PAGE_TYPE.COLLECTION_PAGE]: { chunk: "list", component: "SectionAndCollectionPage" },
    default: { chunk: "list", component: "NotFoundPage" }
  },
  {
    list: () => import(/* webpackChunkName: "list" */ "./component-bundles/list.js"),
    story: () => import(/* webpackChunkName: "story" */ "./component-bundles/story.js"),
    contribute: () => import(/* webpackChunkName: "contribute" */ "./component-bundles/contribute.js")
  }
);

export { pickComponent, getChunkName };
