import React from "react";
import PropTypes from "prop-types";
export function Facebook({ color = "#333", width = 21, height = 21 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        d="M9.917 22v-8.945H7V9.5h2.917V6.687c0-1.484.404-2.636 1.212-3.457.808-.82 1.881-1.23 3.22-1.23 1.085 0 1.97.052 2.651.156V5.32h-1.818c-.682 0-1.15.157-1.402.47-.202.26-.303.676-.303 1.25V9.5h3.22l-.455 3.555h-2.765V22h-3.56z"
      />
      <use fill={color} fillRule="evenodd" />
    </svg>
  );
}
Facebook.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};
