import React from "react";
import PT from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import "./styles.m.css";
import { Facebook } from "../../basic/icons/facebook";
import { Twitter } from "../../basic/icons/twitter";
import { Linkedin } from "../../basic/icons/linkedin";
import { Instagram } from "../../basic/icons/instagram";
import { Youtube } from "../../basic/icons/youtube";
import assetify from "@quintype/framework/assetify";
import QuintypeLogo from "./quintype-logo.png";

import NHlogo from "../../basic/images/nh-white.svg";
import NJlogo from "../../basic/images/nav-white.svg";
import QAlogo from "../../basic/images/qaumi-white.svg";
import { DfpAd } from "../../dfp-ad";
import { PAGE_TYPE } from "../../../constants";

const urduSocialShareLinks = () => {
  return {
    linkedin: "https://www.linkedin.com/in/qaumiawaz",
    youtube: "https://www.youtube.com/channel/UCnTKp4l8hwN0t0y4KWLI17A"
  };
};

const englishSocialShareLinks = () => {
  return {
    linkedin: "https://www.linkedin.com/company/national-herald-india",
    youtube: "https://www.youtube.com/channel/UC0Qc-B4LiHup-paPyo24iWg"
  };
};
const hindiSocialShareLinks = () => {
  return {
    linkedin: "https://www.linkedin.com/company/13384130",
    youtube: "https://www.youtube.com/channel/UCZvQDpPgOQiuzKyFcOJRvIA"
  };
};

const FooterBase = ({
  footerLinks,
  sectionLinks,
  socialLinks,
  publisherSettings,
  publisherAttributes = {},
  pageType,
  storyId
}) => {
  const getSocialShareUrl = socialShare => {
    if (publisherAttributes.lang === "en") {
      return englishSocialShareLinks();
    } else if (publisherAttributes.lang === "hi") {
      return hindiSocialShareLinks();
    } else {
      return urduSocialShareLinks();
    }
  };

  return (
    <div styleName={publisherAttributes.lang === "ur" ? "ur-footer-container" : "footer-container"}>
      <div styleName="footer">
        <div styleName="footer-top">
          <div styleName="logo-with-social-icons">
            <Link href="/" styleName="publisher-logo">
              {publisherAttributes.lang === "hi" ? (
                <img className="nj-logo" src={`${assetify(NJlogo)}`} alt="Navjivan" width="150" height="37" />
              ) : null}
              {publisherAttributes.lang === "ur" ? (
                <img className="qa-logo" src={`${assetify(QAlogo)}`} alt="Qaumi Awaz" width="130" height="32" />
              ) : null}
              {publisherAttributes.lang === "en" ? (
                <img className="nh-logo" src={`${assetify(NHlogo)}`} alt="National Herald" width="300" height="32" />
              ) : null}
            </Link>
            <div styleName="social-icons">
              <div styleName="follow-us">Follow Us</div>
              <div>
                <a
                  href={socialLinks && socialLinks["facebook-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  styleName="social-icon-link"
                >
                  <Facebook color="#111111" width={12} height={12} />
                </a>
                <a
                  href={socialLinks && socialLinks["twitter-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  styleName="social-icon-link"
                >
                  <Twitter color="#111111" width={12} height={12} />
                </a>
                <a
                  href={socialLinks && socialLinks["instagram-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  styleName="social-icon-link"
                >
                  <Instagram color="#111111" width={14} height={14} />
                </a>
                <div styleName="social-icon-link">
                  <a
                    href={getSocialShareUrl().youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                    styleName="social-icon-link"
                  >
                    <Youtube color="#111111" width={13} height={13} />
                  </a>
                </div>
                <div styleName="social-icon-link" className="urdu-last-social-link">
                  <a
                    href={getSocialShareUrl().linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    styleName="social-icon-link"
                  >
                    <Linkedin color="#111111" width={12} height={12} />
                  </a>
                </div>
              </div>
            </div>
            <div styleName="buttons-style">
              <a
                href="/subscribe"
                styleName={`subscribe-link ${publisherAttributes.lang === "ur" ? "ur-subscribe-link" : ""}`}
                target="_blank"
              >
                Subscribe
              </a>
              <a
                href="/contribute"
                styleName={`subscribe-link ${publisherAttributes.lang === "ur" ? "ur-subscribe-link" : ""}`}
                target="_blank"
              >
                Contribute
              </a>
            </div>
          </div>
          <ul styleName="sections-list">{getSectionLinks(sectionLinks)}</ul>
        </div>
        <div>
          <div styleName="footer-links">{getFooterStaticLinks(footerLinks)}</div>
          <div styleName="copyright-container">
            <div styleName="copyright">{publisherSettings && publisherSettings.copyright}</div>
            <div styleName="powered">
              <a href="http://www.quintype.com" target="_blank" rel="noopener noreferrer" styleName="quintype-logo">
                <img
                  src={assetify(QuintypeLogo)}
                  alt="quintype"
                  height="28"
                  width="24"
                  styleName="quintype-logo__img"
                />
              </a>
              <h6 styleName="powered-by">
                Powered by
                <a href="http://www.quintype.com" target="_blank" rel="noopener noreferrer">
                  {" "}
                  Quintype
                </a>
              </h6>
            </div>
          </div>

          {[
            PAGE_TYPE.NEWSLETTER_PAGE,
            PAGE_TYPE.SUBSCRIBE_NP_PAGE,
            PAGE_TYPE.CONTRIBUTE_PAGE,
            PAGE_TYPE.SUBSCRIBE_PAGE,
            PAGE_TYPE.STATIC_PAGE
          ].includes(pageType) ? null : (
            <div
              styleName={`footer__sticky_ad ${pageType === "story-page" ? "storypage" : "homepage"} ${
                publisherAttributes.lang === "ur" ? "urdu" : ""
              }`}
            >
              <DfpAd text={false} adtype="NH_Footer_Sticky" pageType={pageType} storyId={storyId} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Get section names in the footer
function getSectionLinks(sectionLinks) {
  return sectionLinks.map((item, index) => (
    <li styleName="list-item" key={`${item["id"]}${index}`}>
      <Link href={item.completeUrl} styleName="section-link">
        {item.title}
      </Link>
    </li>
  ));
}

// Get static links in the footer
function getFooterStaticLinks(footerLinks) {
  return footerLinks.map(
    item =>
      item.isExternalLink ? (
        <a
          // In some cases completeUrl will be with hostname instead of partial url, so checking the case there
          href={item.completeUrl.includes("https://") ? item.completeUrl : "/" + item.completeUrl}
          target="_blank"
          rel="noopener noreferrer"
          styleName="link"
          key={item.id}
        >
          {item.title}
        </a>
      ) : (
        <Link href={"/" + item.completeUrl} styleName="link" key={item.id}>
          {item.title}
        </Link>
      )
  );
}

function mapStateToProps(state) {
  return {
    footerLinks: get(state, ["qt", "data", "navigationMenu", "footerLinks"], []),
    sectionLinks: get(state, ["qt", "data", "navigationMenu", "default"], []),
    socialLinks: get(state, ["qt", "config", "social-links"], []),
    publisherSettings: get(state, ["qt", "config", "publisher-settings"], []),
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"]),
    pageType: get(state, ["qt", "pageType"], ""),
    storyId: get(state, ["qt", "data", "story", "id"])
  };
}

FooterBase.propTypes = {
  footerLinks: PT.arrayOf(
    PT.shape({
      isExternalLink: PT.bool,
      completeUrl: PT.string,
      title: PT.string
    })
  ),
  sectionLinks: PT.arrayOf(
    PT.shape({
      completeUrl: PT.string,
      title: PT.string
    })
  ),
  socialLinks: PT.object,
  publisherSettings: PT.object,
  publisherAttributes: PT.object,
  pageType: PT.string,
  storyId: PT.string
};

export const Footer = connect(
  mapStateToProps,
  null
)(FooterBase);
