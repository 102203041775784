import React from "react";

const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27.83" viewBox="0 0 28 27.83">
    <path
      d="M268 252.6a14 14 0 1 0-16.187 13.83v-9.783h-3.557V252.6h3.557v-3.084c0-3.509 2.089-5.447 5.288-5.447a21.563 21.563 0 0 1 3.134.273v3.444h-1.766a2.023 2.023 0 0 0-2.281 2.187v2.627h3.882l-.62 4.047h-3.262v9.783A14 14 0 0 0 268 252.6z"
      fill="#fff"
      data-name="Path 502"
      transform="translate(-240 -238.597)"
    />
  </svg>
);

export default FacebookIcon;
