import React from "react";
import { func, object } from "prop-types";

import "./profileImage.m.css";

const ProfileImage = ({ member, onClick }) => {
  if (member["avatar-url"] || member["avatar_url"]) {
    return (
      <div styleName="profile-wrapper" onClick={onClick} title={member.name}>
        <img src={member["avatar-url"] || member["avatar_url"]} alt={member.name} styleName="profile-pic" />
      </div>
    );
  }

  return (
    <div
      styleName="profile-wrapper-circle"
      onClick={onClick}
      title={member.name === null ? member["first-name"] : member.name}
    >
      <span styleName="profile-circle">
        {member.name === null ? member["first-name"].charAt(0) : member.name.charAt(0)}
      </span>
    </div>
  );
};

ProfileImage.propTypes = {
  onClick: func,
  member: object
};

export default ProfileImage;
