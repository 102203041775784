import React, { useEffect, useState,useRef } from "react";
import PT from "prop-types";
import get from "lodash/get";
import { NavbarSearch } from "../navbar-search";
import { MenuItems } from "./menu-items";
import { AppLogo } from "../app-logo";
import { SocialFollow } from "../../basic/social-follow";
import { Couplet } from "./couplet";
import { connect } from "react-redux";

import { SisterCompanies } from "../sister-companies";
import { HamburgerIcon } from "./hamburger-icon";
import LoginView from "../../rows/login-view";
import PopupModal from "../../atoms/popup-modal";

import { existingCustomerCheck } from "../../utils/api";
import { createNewCustomer } from "../../utils/api";
import { existingSubscriptionCheck } from "../../utils/api";
import { setLoading, setMember } from "../../helper/actions";

import "./styles.m.css";

const NavBarBase = props => {
  const {
    member,
    setLoading,
  } = props;
  const [hamburgerOpened, setHamburgerOpened] = useState(false);
  const [isLoginPopupViewOpen, setIsLoginPopupViewOpen] = useState(false);

  const toggleOffcanvasMenu = () => setHamburgerOpened(!hamburgerOpened);

  const onLoginClick = () => {
    hamburgerOpened && setHamburgerOpened(false);
    setIsLoginPopupViewOpen(!isLoginPopupViewOpen);
  };

  // Login Popup Modal View
  const loginPopupView = () => {
    if (!isLoginPopupViewOpen) {
      return null;
    }

    return (
      <PopupModal closePopup={onLoginClick}>
        <LoginView closePopup={onLoginClick} makePayment={false} />
      </PopupModal>
    );
  };

  const customerCheck = async (email) => {
    let displayname = email;
    let firstname = (member["first-name"] ? member["first-name"] : "");
    let mobile = (member["phone-number"] ? member["phone-number"] : "");
    let address = "";
    let city = "";
    let state = "";
    let zip = "";
    let gender = "";
    if(member.metadata){
      address = (member.metadata["address"] ? member.metadata["address"] : "");
      city = (member.metadata["city"] ? member.metadata["city"] : "");
      state = (member.metadata["state"] ? member.metadata["state"] : "");
      zip = (member.metadata["pincode"] ? member.metadata["pincode"] : "");
      gender = (member.metadata["gender"] ? member.metadata["gender"] : "");
    }
    try {
      const response = await existingCustomerCheck(displayname, email);
      if (response === "new") {
        const result = await createNewCustomer(displayname,email,firstname,mobile,address,city,state,zip,gender);
        localStorage.setItem('userID', result);
        localStorage.setItem('userEmail',email);
        const event = new CustomEvent("userIDUpdated", { detail: result });
        window.dispatchEvent(event);
        return "new";
      } else {
        localStorage.setItem('userID', response[0]["customer_id"]);
        localStorage.setItem('userEmail',email);
        const event = new CustomEvent("userIDUpdated", { detail: response[0]["customer_id"] });
        window.dispatchEvent(event);
        return "old";
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      setLoading(false);
      return false;
    }
  };

  const debounceTimeoutRef = useRef(null);
  const [similarMember,setSimilarMember] = useState(member? member : null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      localStorage.setItem("activeDigitalSub", false);
      if(member){
        setSimilarMember(member);
        if (("id" in member)) {
          if(member !== similarMember){
            setLoading(true);
            (async function zohoHandleMember() {
              try {
                const result = await customerCheck(member.email );
                if (result == "old") {
                  const sub = await existingSubscriptionCheck(localStorage.getItem("userID"),["2793","3273","3274","3234", "658", "657", "659"]);
                  if (sub.length >= 1) {
                    localStorage.setItem("activeDigitalSub", true);
                    setLoading(false);
                  } else {
                    localStorage.setItem("activeDigitalSub", false);
                    setLoading(false);
                  }
                }else if(result == "new"){
                  localStorage.setItem("activeDigitalSub",false);
                  setLoading(false);
                }
              } catch (error) {
                console.error('An error occurred:', error);
                setLoading(false);
              }
            })();
          }
        }
      }
    },1500);
  }, [member]);

  const styleName = props.publisherAttributes.lang === "ur" ? "urdu-search-row-one" : "search-row-one";
  const langClassName = props.publisherAttributes.lang === "ur" ? "urdu-navbar" : "navbar";
  const BleedingRowMenuStyle = props.publisherAttributes.lang === "ur" ? "ur-bleeding-row--menu" : "bleeding-row--menu";

  return (
    <div styleName={langClassName}>
      <div styleName="bleeding-row">
        <div
          styleName={`container ${
            props.publisherAttributes.lang === "ur" ? "ur-container--padded" : "container--padded"
          }`}
        >
          <HamburgerIcon onClick={toggleOffcanvasMenu} />
          <AppLogo />
          <SocialFollow
            styleName="social-follow"
            socialLinks={props.socialLinks}
            color={props.publisherAttributes.lang === "ur" ? "#af33c3" : "#056ff7"}
            width={12}
            height={12}
          />
          <NavbarSearch {...props} searchIconColor="#333" styleName={styleName} />
          {props.publisherAttributes.lang === "ur" && <Couplet coupletData={props.coupletSummary} />}
          <SisterCompanies styleName="sister-companies" onLoginClick={onLoginClick} />
        </div>
      </div>

      <div id="secondary-menu" styleName={`bleeding-row ${BleedingRowMenuStyle}`}>
        <div styleName={`container menu-container ${hamburgerOpened ? "menu-container--open" : ""}`}>
          <MenuItems items={get(props, ["menu", "default"], [])} closeMenu={toggleOffcanvasMenu} />
          <NavbarSearch {...props} styleName="search-row-two" />
          <div styleName="offcanvas-static-links">
            <p styleName="offcanvas-menu__text">Follow Us</p>
            <SocialFollow
              styleName="social-follow"
              socialLinks={props.socialLinks}
              color={props.publisherAttributes.lang === "ur" ? "#af33c3" : "#056ff7"}
              width={12}
              height={12}
            />
            <SisterCompanies
              styleName="sister-companies"
              onLoginClick={onLoginClick}
              onHamburgerToggle={toggleOffcanvasMenu}
            />
          </div>
          <div
            styleName={`menu-overlay ${hamburgerOpened ? "menu-overlay--open" : ""}`}
            onClick={toggleOffcanvasMenu}
          />
        </div>
      </div>

      {/* Popup Modal's */}
      {loginPopupView()}
      </div>
  );
};

NavBarBase.propTypes = {
  socialLinks: PT.object,
  publisherAttributes: PT.object,
  coupletSummary: PT.string
};

function mapStateToProps(state) {
  return {
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"]),
    member: state.member || null
  };
}

const mapDispatchToProps = dispatch => ({
  setMember: member => dispatch(setMember(member)),
  setLoading: isLoading => dispatch(setLoading(isLoading)),
});

export const NavBar = connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(NavBarBase);
