import React, { useEffect } from "react";
import throttle from "lodash/throttle";
import { bool, object, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { NavBar } from "./nav-bar";
import { DfpAd } from "../dfp-ad/";

import "./header.m.css";

// Common wrapper for navigation. We could add OffcanvasMenu, Navbar etc components here.

const HeaderBase = props => {
  useEffect(() => {
    if (global.width > 768) {
      window.addEventListener("scroll", throttle(handleScroll, 50));
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const header = document.getElementById("header");
    const secondaryHeader = document.getElementById("secondary-menu");
    const throshold = header.offsetHeight;

    if (window.pageYOffset > throshold) {
      secondaryHeader.classList.add("header-fixed");
    } else {
      secondaryHeader.classList.remove("header-fixed");
    }
  };

  return (
    <React.Fragment>
      {props.isActivePlan ? null : (
        <div styleName="ad-top">
          <DfpAd
            text={false}
            adtype="NH_HP_728x90"
            height="90"
            width="728"
            storyId={props.storyId}
            pageType={props.pageType}
          />
        </div>
      )}
      <div styleName={props.publisherAttributes.lang === "ur" ? "urdu-container" : "container"}>
        <NavBar {...props} />
      </div>
    </React.Fragment>
  );
};

HeaderBase.propTypes = {
  isActivePlan: bool,
  publisherAttributes: object,
  storyId: string,
  pageType: string
};

function getCoupletSummary(state) {
  const coupletSummary = get(state, ["qt", "data", "getCoupletSummary"], "");
  const splitBySlash = coupletSummary && coupletSummary.split("/");
  return splitBySlash;
}

function mapStateToProps(state) {
  return {
    menu: get(state, ["qt", "data", "navigationMenu"], []),
    storyId: get(state, ["qt", "data", "story", "id"]),
    pageType: get(state, ["qt", "pageType"]),
    socialLinks: get(state, ["qt", "config", "social-links"], []),
    coupletSummary: getCoupletSummary(state),
    isActivePlan: get(state, ["userReducer", "isActivePlan"], false),
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"], [])
  };
}

export const Header = connect(
  mapStateToProps,
  () => ({})
)(HeaderBase);
