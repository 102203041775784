export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  TAG_PAGE: "tag-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  CATALOG_PAGE: "catalog-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  STATIC_PAGE: "static-page",
  AUTHOR_PAGE: "author-page",
  CONTACT_PAGE: "contact-page",
  COLLECTION_PAGE: "collection-page",
  ELECTION_PAGE: "election-page",
  DASHBOARD_PAGE: "dashboard-page",
  CONTRIBUTE_PAGE: "contribute-page",
  SUBSCRIBE_PAGE: "subscribe-page",
  SUBSCRIPTION_PAGE: "subscription-page",
  SUBSCRIBE_NP_PAGE: "subscribe-np-page",
  NEWSLETTER_PAGE: "newsletter-page",
  VISUAL_STORY: "visual-story"
});

export const FALLBACK_PUBLISHER_CONFIG = Object.freeze({
  lang: "en",
  title: "National Herald India",
  language_direction: "ltr",
  facebook_page_id: "201184373694750",
  gtm_id: "GTM-PPHJB7",
  ga_id: "UA-87151630-1",
});

export const TAG_PAGE_URL_PREFIX = "/topic/";
