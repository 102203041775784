import React from "react";

function KeyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 17 7">
      <g>
        <g opacity="1" transform="rotate(45 9 3.49)">
          <g opacity=".5">
            <path
              fill="#6c6c6c"
              d="M11.572-.086a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-2.429A3.429
              3.429 0 0 0 8.143.914c0 .347.047.685.143 1l-5 5L3 9.485h2.286L6.572
              8.2V6.77H8l.429-.428V4.914h1.428l.715-.714a3.45 3.45 0 0 0 1 .143 3.429
              3.429 0 1 0 0-6.858z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default KeyIcon;
