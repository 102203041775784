import wretch from "wretch";

const apiClient = wretch().options({ credentials: "same-origin" });

export function getRequest(url, params) {
  var client = apiClient.url(url);
  if (params) client = client.query(params);
  return client.get();
}

export function storyPageLoadItems(pageNumber, mostPopular) {
  const FIELDS =
    "id,headline,subheadline,metadata,slug,url,hero-image-s3-key,tags,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,authors,sections,story-template,cards";

  return global
    .wretch("/api/v1/stories")
    .query({
      fields: FIELDS,
      limit: 5,
      offset: 5 * pageNumber
    })
    .get()
    .json(response => response.stories.map(story => ({ story, mostPopular: mostPopular })));
}

export const loadNextStories = pageNumber => {
  const FIELDS = "id,slug,first-published-at,last-published-at";
  return global
    .wretch("/api/v1/collections/latest-news")
    .query({
      fields: FIELDS,
      limit: 20
    })
    .get()
    .json(response =>
      response.items.filter(i => i.type === "story").map(({ story }) => ({ slug: story.slug, id: story.id }))
    );
};

export const bottomStickyTopStory = () => {
  return global
    .wretch("/api/v1/collections/main")
    .get()
    .json(r => r.items[0].story)
    .catch(error => console.log("error", error));
};

// Returns the current User
export function getUser() {
  return global
    .wretch()
    .url("/api/auth/v1/users/me")
    .get()
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

// Logout the User
export function logout() {
  return global
    .wretch()
    .url("/api/auth/v1/logout")
    .get()
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

// Update User Information
export function updateUserInformation(data) {
  return global
    .wretch()
    .url("/api/auth/v1/users/profile")
    .post(data)
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

// login user
export function loginUser(email, password) {
  return global
    .wretch()
    .url("/api/auth/v1/login")
    .headers({ "Content-Type": "application/json" })
    .post({
      username: email,
      password: password
    })
    .json(member => member)
    .catch(ex => Promise.reject(ex));
}

// signup the user
export function signUpUser(options) {
  return global
    .wretch()
    .url("/api/auth/v1/signup")
    .headers({ "Content-Type": "application/json" })
    .post(options)
    .json(member => member)
    .catch(ex => Promise.reject(ex));
}

export function forgotPassword(email) {
  return global
    .wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/forgot-password")
    .headers({ "Content-Type": "application/json" })
    .post({ email })
    .json(response => response)
    .catch(ex => Promise.reject(ex));
}

// Reset the password
export function resetPassword(token, password, repeatPassword) {
  return global
    .wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/reset-password-with-token")
    .post({
      token: token,
      "new-password": password,
      "confirm-password": repeatPassword
    })
    .json(response => response)
    .catch(ex => {
      if (ex.message) {
        const res = JSON.parse(ex.message);
        return Promise.reject(res.error);
      }

      return Promise.reject(ex);
    });
}

// Update User Metadata
export function updateUserMetadata(data) {
  return global
    .wretch()
    .options({ credentials: "same-origin" })
    .url("/api/member/metadata")
    .post({ metadata: data })
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

// Generate JWT token from server which is used in access-type
export function generateJwtToken(email, id, name) {
  return global
    .wretch()
    .url("/generate-token")
    .post({ email, id, name })
    .json(result => result.token)
    .catch(ex => Promise.reject(ex));
}

export function sendSupportEmail(data) {
  return global
    .wretch()
    .url("/support-mail")
    .post(data)
    .res(res => res)
    .catch(ex => Promise.reject(ex));
}

export function sendSubscribeUserNPEmail(data) {
  return global
    .wretch()
    .url("/subscribe-user")
    .post(data)
    .res(res => res)
    .catch(ex => Promise.reject(ex));
}

export function sendDigitalSubscribeUserEmail(data) {
  return global
    .wretch()
    .url("/digital-subscribe-user")
    .post(data)
    .res(res => res)
    .catch(ex => Promise.reject(ex));
}

export function sendSubscribeUserToAdminEmail(data) {
  return global
    .wretch()
    .url("/subscription")
    .post(data)
    .res(res => res)
    .catch(ex => Promise.reject(ex));
}

export function getPageCommentsData(accountId, url) {
  const encodedUrl = btoa(url);
  return global
    .wretch()
    .url(`https://www.metype.com/api/v1/accounts/${accountId}/pages/${encodedUrl}`)
    .get()
    .json(res => res)
    .catch(ex => Promise.reject(ex));
}

export const submitNewsLetter = (id, token, body) => {
  return global
    .wretch()
    .url("/newsletter")
    .query({ id, token })
    .post(body)
    .text(res => res);
};

export const sendTransactionalEmail = options => {
  return global
    .wretch()
    .url("/transactional-emails")
    .headers({ "Content-Type": "application/json" })
    .post(options)
    .res(res => res);
};

export const getCountry = () => {
  return global
    .wretch()
    .url("https://ipinfo.io/json?token=06e346377eff84")
    .get()
    .json(res => res)
    .catch(err => console.log(err));
};

export const getAccessToken = async () => {
  try {
    const accessToken = await global.wretch()
      .url("/zoho-billing-access-token")
      .headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
      .post()
      .text();

    localStorage.setItem("AToken",accessToken);
    return accessToken;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
}

export const createNewCustomer = async (name, email,fullName,number,address,city,state,zip,gender) => {
  let accessToken = localStorage.getItem("AToken");
  try {
    const response = await global.wretch()
      .url('/zoho-create-customer')
      .headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
      .post({ name, email, accessToken ,number, fullName,address,city,state,zip,gender})
      .json();
    const customerId = BigInt(response.customer_id).toString();
    localStorage.setItem("activeDigitalSub",false);
    return customerId;
  } catch (error) {
    if (error.status === 401) {
      console.error("Token not correct, unauthorized.");
      let newToken = await getAccessToken();
      const newResponse = await global.wretch()
        .url('/zoho-create-customer')
        .headers({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .post({ name, email, accessToken: newToken ,number, fullName,address,city,state,zip,gender})
        .json();
      const customerId = BigInt(newResponse.customer_id).toString();
      localStorage.setItem("activeDigitalSub",false);
      return customerId;
    } else {
      console.error("Error during API call:", error);
      return false;
    }
  }
}

export const updateExistingCustomer = async (customerId, name, email, number ,fullName, address, city, state, zip,country,gender,dob, currency, pricebookID) => {
  let accessToken = localStorage.getItem("AToken");
  try {
    const response = await global.wretch()
      .url('/zoho-update-customer')
      .headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
      .put({ customerId, name, email, accessToken, number, fullName, address, city, state, zip,country ,gender,dob, currency, pricebookID})
      .json();

    const updatedCustomerId = BigInt(response.customer_id).toString();
    return updatedCustomerId;

  } catch (error) {
    if (error.status === 401) {
      console.error("Token not correct, unauthorized.");
      let newToken = await getAccessToken();
      const newResponse = await global.wretch()
        .url('/zoho-update-customer')
        .headers({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .put({ customerId, name, email, accessToken: newToken, number, fullName, address, city, state, zip,country ,gender,dob, currency, pricebookID})
        .json();

      const updatedCustomerId = BigInt(newResponse.customer_id).toString();
      return updatedCustomerId;
    } else {
      console.error("Error during API call:", error);
      return false;
    }
  }
};

export const getPaymentUrl = async (customerID,zohoPlanSelected,customValue = false) => {
  let accessToken = localStorage.getItem("AToken");
  try {
    const response = await global.wretch()
      .url('/zoho-payment-url')
      .headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
      .post({ customerID , accessToken,zohoPlanSelected ,customValue })
      .json();

    return response;
  } catch (error) {
    if (error.status === 401) {
      console.error("Token not correct, unauthorized.");
      let newToken = await getAccessToken();
      const newResponse = await global.wretch()
        .url('/zoho-payment-url')
        .headers({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .post({ customerID , accessToken: newToken,zohoPlanSelected ,customValue })
        .json();

      return newResponse;
    } else {
      console.error("Error during API call:", error);
      return false;
    }
  }
}

export const existingCustomerCheck = async (name, email) => {
  let accessToken = localStorage.getItem("AToken");
  try {
    const response = await global.wretch()
      .url("/zoho-existing-customer-check")
      .query({ email, accessToken })
      .headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
      .get()
      .json();

    if (response.customers && response.customers.length === 1) {
      return response.customers;
    } else {
      return "new";
    }

  } catch (error) {
    if (error.status === 401) {
      console.error("Token not correct, unauthorized.");
      let newToken = await getAccessToken();
      const newResponse = await global.wretch()
        .url("/zoho-existing-customer-check")
        .query({ email, accessToken: newToken })
        .headers({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .get()
        .json();

      if (newResponse.customers && newResponse.customers.length === 1) {
        return newResponse.customers;
      } else {
        return "new";
      }
    } else {
      console.error("Error during API call:", error);
    }
  }
};

export const downloadInvoicePDF = async (invID) => {
  let accessToken = localStorage.getItem("AToken");
  try {
    const response = await global.wretch()
      .url("/zoho-get-invoice-pdf")
      .query({ invID, accessToken })
      .headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
      .get()
      .blob(); // Convert the response into a blob (binary)

    // Create URL for the blob and trigger download
    const url = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `invoice_${invID}.pdf`); // Name the file
    document.body.appendChild(link);
    link.click(); // Trigger the download
    link.remove(); // Clean up the link element
  }  catch (error) {
    if (error.status === 401) {
      console.error("Token not correct, unauthorized.");
      let newToken = await getAccessToken();
      const newResponse = await global.wretch()
        .url("/zoho-get-invoice-pdf")
        .query({ invID, accessToken: newToken })
        .headers({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .get()
        .blob(); // Convert the response into a blob (binary)

      // Create URL for the blob and trigger download
      const url = window.URL.createObjectURL(newResponse);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoice_${invID}.pdf`); // Name the file
      document.body.appendChild(link);
      link.click(); // Trigger the download
      link.remove(); // Clean up the link element
    } else {
      console.error('Error during PDF download:', error);
      alert('Failed to download the invoice. Please try again later.');
      return false;
    }
  }
};
export const cancelZohoSub = async (subID) => {
  let accessToken = localStorage.getItem("AToken");
  try {
    const response = await global.wretch()
      .url("/zoho-cancel-subscription")
      .headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
      .post({ subID, accessToken })
      .json();

    return response;
  } catch (error) {
    if (error.status === 401) {
      console.error("Token not correct, unauthorized.");
      let newToken = await getAccessToken();
      const newResponse = await global.wretch()
        .url("/zoho-cancel-subscription")
        .headers({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .post({ subID, accessToken: newToken })
        .json();

      return newResponse;
    } else {
      console.error('Error during subscription cancellation:', error);
      return false;
    }
  }
};


export const getInvoiceNumber = async (subID) => {
  let accessToken = localStorage.getItem("AToken");
  try{
    const response = await global.wretch()
      .url("/zoho-get-invoice-number")
      .query({subID,accessToken})
      .headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
      .get()
      .json();
    return response["subscription"]["child_invoice_id"];
  } catch (error) {
    if (error.status === 401) {
      console.error("Token not correct, unauthorized.");
      let newToken = await getAccessToken();
      const newResponse = await global.wretch()
        .url("/zoho-get-invoice-number")
        .query({subID,accessToken: newToken})
        .headers({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .get()
        .json();
      return newResponse["subscription"]["child_invoice_id"];
    } else {
      console.error("Error during API call:", error);
      return false;
    }
  }
};
export const getZohoUserData = async (userID) => {
  let accessToken = localStorage.getItem("AToken");
    try{
      const response = await global.wretch()
        .url("/zoho-retrieve-customer")
        .query({ userID, accessToken})
        .headers({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .get()
        .json();
      return response.customer;
    } catch (error) {
      if (error.status === 401) {
        console.error("Token not correct, unauthorized.");
        let newToken = await getAccessToken();
        const newResponse = await global.wretch()
          .url("/zoho-retrieve-customer")
          .query({ userID, accessToken: newToken})
          .headers({
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          })
          .get()
          .json();
        return newResponse.customer;
      } else {
        console.error("Error during API call:", error);
        return false;
      }
    }
};

export const existingSubscriptionCheck = async (customerID,zohoPlanSelected,planStatus = "ACTIVE") => {
  let accessToken = localStorage.getItem("AToken");
    try {
      const response = await global.wretch()
        .url("/zoho-subscription-check")
        .query({ customerID, accessToken,planStatus})
        .headers({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .get()
        .json();
        if (zohoPlanSelected === "all") {
          if (response.subscriptions.length > 0) {
            return response.subscriptions;
          }
        } else {
          // Ensure `zohoPlanSelected` is treated as an array of plan codes
          const planCodes = Array.isArray(zohoPlanSelected) ? zohoPlanSelected : [zohoPlanSelected];

          if (response.subscriptions.length > 0) {
            // Filter subscriptions based on whether their plan_code matches any in the `planCodes` array
            const matchingSubscriptions = response.subscriptions.filter(sub => planCodes.includes(sub.plan_code));

            if (matchingSubscriptions.length > 0) {
              return matchingSubscriptions;
            }
          }
        }
         return false;

    } catch (error) {
      if (error.status === 401) {
        console.error("Token not correct, unauthorized.");
        let newToken = await getAccessToken();
        const newResponse = await global.wretch()
          .url("/zoho-subscription-check")
          .query({ customerID,  accessToken: newToken,planStatus})
          .headers({
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          })
          .get()
          .json();
          if (zohoPlanSelected === "all") {
            if (newResponse.subscriptions.length > 0) {
              return newResponse.subscriptions;
            }
          } else {
            // Ensure `zohoPlanSelected` is treated as an array of plan codes
            const planCodes = Array.isArray(zohoPlanSelected) ? zohoPlanSelected : [zohoPlanSelected];

            if (newResponse.subscriptions.length > 0) {
              // Filter subscriptions based on whether their plan_code matches any in the `planCodes` array
              const matchingSubscriptions = newResponse.subscriptions.filter(sub => planCodes.includes(sub.plan_code));

              if (matchingSubscriptions.length > 0) {
                return matchingSubscriptions;
              }
            }
          }
          return false;
      } else {
        console.error("Error during API call:", error);
        return false;
      }
    }


};
