import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import PT from "prop-types";
import Slider from "react-slick";
import "./breaking-news-view.m.css";

function breakingNewsItem(story, props) {
  function langClass() {
    if (props.publisherAttributes.lang === "en") {
      return "en-breaking-news";
    } else if (props.publisherAttributes.lang === "hi") {
      return "hi-breaking-news";
    }
  }

  return (
    <li styleName="breaking-news-item" key={story.id}>
      <a
        styleName="breaking-news-header"
        className={langClass()}
        href={story.metadata && story.metadata["linked-story-slug"]}
        target="_blank"
        rel="noopener noreferrer"
      >
        {story.headline}
      </a>
    </li>
  );
}

function BreakingNewsBase(props) {
  if (props.pageType !== "home-page" || (props.breakingNewsLoaded && props.breakingNews.length === 0)) {
    return <span />;
  }

  const getBreakingNewsTitle = () => {
    if (props.publisherAttributes.lang === "en") {
      return "NEWSFLASH";
    } else if (props.publisherAttributes.lang === "hi") {
      return "मुख्य खबरें";
    } else {
      return "تازہ ترین";
    }
  };
  function getBreakingNewsTitleStyle() {
    if (props.publisherAttributes.lang === "ur") {
      return "ur-breaking-news-title";
    } else {
      return "breaking-news-title";
    }
  }

  const SLIDER_SETTINGS = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    heightMode: "current",
    withoutControls: true,
    focusOnSelect: true,
    autoplay: true,
    swipeToSlide: true,
    autoplaySpeed: 4000
  };

  return (
    <ul styleName={props.publisherAttributes.lang === "ur" ? "ur-breaking-news-container" : "breaking-news-container"}>
      <div styleName={getBreakingNewsTitleStyle()}>{getBreakingNewsTitle()}</div>
      <Slider {...SLIDER_SETTINGS}>
        {props.breakingNewsLoaded ? (
          props.breakingNews.map(story => breakingNewsItem(story, props))
        ) : (
          <div styleName="breaking-news-item">
            <div styleName="breaking-news-header"> </div>
          </div>
        )}
      </Slider>
    </ul>
  );
}

function mapStateToProps(state) {
  return {
    pageType: get(state, ["qt", "pageType"], []),
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"], "")
  };
}

BreakingNewsBase.propTypes = {
  breakingNews: PT.array,
  breakingNewsLoaded: PT.bool,
  pageType: PT.string,
  publisherAttributes: PT.object
};
breakingNewsItem.propTypes = {
  publisherAttributes: PT.object
};

export const BreakingNewsView = connect(
  mapStateToProps,
  null
)(BreakingNewsBase);
