import React from "react";

function PhoneIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
      <g>
        <g opacity="1" transform="rotate(4 6.775 7.19)">
          <g opacity=".5">
            <path
              fill="#6c6c6c"
              d="M2.995 5.398c1.219 1.512 4.047 4.34 5.559 5.608.536-.536.634-.683
              1.17-1.17.341-.39.878-.293 1.317 0l2.194 1.414c.44.244.342.926 0 1.316-2.194
              2.195-4.242 1.658-7.412-1.024-1.268-1.121-2.292-2.096-3.414-3.413C-.273 5.008-.809
               2.91 1.385.765c.39-.39 1.073-.438 1.366 0l1.365 2.146c.293.439.39.975 0
               1.317-.487.536-.634.682-1.121 1.17z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PhoneIcon;
