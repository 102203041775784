import React from "react";
import { bool, string, func } from "prop-types";

import "./checkbox.m.css";

const CheckBox = ({ isChecked, text, onChange }) => (
  <label styleName="wrapper" className="checkbox">
    <span>{text}</span>
    <input type="checkbox" checked={isChecked} onChange={onChange} />
    <span styleName="checkbox-type" />
  </label>
);

CheckBox.propTypes = {
  isChecked: bool,
  text: string,
  onChange: func
};

export default CheckBox;
