import React, { useState, useEffect } from "react";
import { string, number, func, bool } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import GoogleAuth from "../../atoms/google-auth";
import FacebookAuth from "../../atoms/facebook-auth";
import { loginUser, signUpUser, sendTransactionalEmail } from "../../utils/api";

import { setMember, setLoading, setUserPlans } from "../../helper/actions";

// import { setAccessTypeUser } from "../../helper/access-type";

import "./login-view.m.css";
import PersonIcon from "../../atoms/icons/PersonIcon";
import EmailIcon from "../../atoms/icons/EmailIcon";
import KeyIcon from "../../atoms/icons/KeyIcon";
import PhoneIcon from "../../atoms/icons/PhoneIcon";
import CheckBox from "../../atoms/checkbox";
import ForgotPasswordView from "../../molecules/forgot-password";

const LoginView = props => {
  const {
    closePopup,
    googleClientId,
    facebookId,
    setMember,
    setLoading,
    setUserPlans,
    publisherLang,
    makePayment,
    razorPayPayment,
    showOnlySignup,
    showAllFields,
    changeTermsAndConditionURL = false
  } = props;
  const [signIn, setSignIn] = useState(!showOnlySignup);
  const [errorMsg, setError] = useState("");
  const [showForgotPasswordView, setShowForgotPasswordView] = useState(false);
  const [formvalues, setformvalues] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    gender: "",
    dob: "",
    state: "",
    city: "",
    pincode: "",
    address: ""
  });
  const pincodeRegex = /^[0-9]+$/i;
  const cityRegex = /^[a-zA-Z]+((\s)*[a-zA-Z]*)*$/i;
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setisSubmit] = useState(false);
  const [dateType, setDateType] = useState("text");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const termsChange = e => setIsTermsAccepted(e.target.checked);


  const dateFocus = () => {
    setDateType("date");
  };
  // function setUserOnAT(member) {
  //   const { email, id } = member;

  //   const mobileNumber = get(member, ["phone-number"], "");
  //   const name = member["first-name"] || member.name;
  //   if (global.AccessType) {
  //     setAccessTypeUser(email, mobileNumber, id, name)
  //       .then(response => console.log("setAccessTypeUser response :>> ", response))
  //       .catch(e => console.error("err setting AT user:-", e))
  //       .finally(() => setLoading(false));
  //   } else {
  //     setLoading(false);

  //   }
  // }
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const prevDay = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getDate();

  function toggleSignInSignUp(showSignin) {
    setSignIn(!!showSignin);
  }

  function onForgotPasswordClick() {
    setShowForgotPasswordView(!showForgotPasswordView);
  }

  const handleChange = e => {
    var { name, value } = e.target;
    if (name === "pincode") {
      if (value === "") {
        value = "";
      } else {
        value = pincodeRegex.test(value) ? value : formvalues.pincode;
      }
    }
    if (name === "city") {
      if (value === "") {
        value = "";
      } else {
        value = cityRegex.test(value) ? value : formvalues.city;
      }
    }
    if (name === "state") {
      if (value === "") {
        value = "";
      } else {
        value = cityRegex.test(value) ? value : formvalues.state;
      }
    }
    setformvalues({ ...formvalues, [name]: value });
  };
  const handleSubmit = e => {
    e.preventDefault();
    setformErrors(validate(formvalues));
    setisSubmit(true);
  };

  useEffect(
    () => {
      if (!signIn) {
        if (
          (showOnlySignup
            ? showAllFields
              ? Object.keys(formErrors).length === 2
              : Object.keys(formErrors).length <= 5
            : Object.keys(formErrors).length === 0) &&
          isSubmit &&
          isTermsAccepted
        ) {
          setLoading(true);
          signUpUser({
            username: formvalues.email,
            password: formvalues.password,
            email: formvalues.email,
            "first-name": formvalues.username,
            "phone-number": Number(formvalues.phone) || 0,
            gender: formvalues.gender,
            dob: formvalues.dob,
            state: formvalues.state,
            city: formvalues.city,
            pincode: formvalues.pincode,
            address: formvalues.address,
            "send-welcome-email-delay": 0,
            "dont-login": false,
            metadata: {
              gender: formvalues.gender,
              dob: formvalues.dob,
              state: formvalues.state,
              city: formvalues.city,
              pincode: formvalues.pincode,
              address: formvalues.address
            }
          })
            .then(signupResponse => {
              if (get(signupResponse, "error", null)) {
                throw new Error(signupResponse.error.message);
              }

              if (get(signupResponse, "user", null)) {
                setMember(signupResponse.user);
                // setUserOnAT(signupResponse.user);
              }
              makePayment && razorPayPayment(signupResponse.user);
              const userDetails = {
                userEmail: signupResponse.user.email,
                userName: signupResponse.user["first-name"] || signupResponse.user.name,
                type: "welcome"
              };
              sendTransactionalEmail(userDetails).then(res => console.log("sendTransactionalEmail", res));
              !showOnlySignup && closePopup();
            })
            .catch(e => {
              if (e.status === 409) {
                setError(`The email '${formvalues.email}' already exists!`);
              } else {
                setError("Oops! Something went wrong");
              }
              setLoading(false);
            });
        }
      } else if (!formErrors.email && !formErrors.password && signIn && formvalues.email && formvalues.password) {
        setLoading(true);
        loginUser(formvalues.email, formvalues.password)
          .then(currentUser => {

            if (get(currentUser, "error", null)) {
              throw new Error(currentUser.error.message);
            }
            setMember(currentUser.user);
            // setUserOnAT(currentUser.user);
            makePayment && razorPayPayment(currentUser.user);
            closePopup();
          })
          .catch(ex => {
            if (ex.status === 401) {
              setError("Invalid Credentials, Please try again!");
            } else {
              setError("Oops! Something went wrong");
            }
            setLoading(false);
          });
      }
    },
    [formErrors, isSubmit]
  );

  const validate = values => {
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.username) {
      error.username = "please enter fullname";
    }

    if (!values.email) {
      error.email = "please enter email";
    } else if (!regex.test(values.email)) {
      error.email = "please enter valid email";
    }

    if (!values.phone) {
      error.phone = "please enter phone number";
    } else if (values.phone.length < 10) {
      error.phone = "enter valid mobile number";
    } else if (values.phone.length > 10) {
      error.phone = "enter valid mobile number";
    } else if (values.phone.length === 10) {
    }

    if (!values.password) {
      error.password = "please enter password";
    } else if (values.password.length < 8 && !signIn) {
      error.password = "password cannot be less than 8 characters";
    }

    if (!values.confirmPassword) {
      error.confirmPassword = "re enter password";
    } else if (values.confirmPassword !== values.password) {
      error.confirmPassword = "passwords did not match";
    }

    if (!isTermsAccepted) {
      error.isTermsAccepted = "accept the terms and conditions";
    }

    if (!values.gender) {
      error.gender = "please select gender";
    }
    if (!values.dob) {
      error.dob = "please select dob";
    }
    if (!values.state) {
      error.state = "please enter state";
    }
    if (!values.city) {
      error.city = "please enter city";
    }
    if (!values.address) {
      error.address = "please enter address";
    }
    if (!values.pincode) {
      error.pincode = "please enter pincode";
    }

    return error;
  };

  return (
    <React.Fragment>
      {showForgotPasswordView ? (
        <ForgotPasswordView toggleForgotPasswordView={onForgotPasswordClick} />
      ) : (
        <div styleName={`wrapper ${showOnlySignup ? "only-signup-wrapper" : ""}`}>
          {!showOnlySignup && <span styleName="description">Log in to get the complete ad free experience</span>}

          <div style={{ display: "flex", height: "60px" }}>
            <div styleName="google-auth">
              <GoogleAuth
                text="Continue with Google"
                googleClientId={googleClientId}
                setMember={setMember}
                setLoading={setLoading}
                closePopup={closePopup}
                setUserPlans={setUserPlans}
              />
            </div>
            <div styleName="facebook-auth">
              <FacebookAuth
                text="Continue with Facebook"
                facebookId={facebookId}
                setMember={setMember}
                setLoading={setLoading}
                closePopup={closePopup}
                setUserPlans={setUserPlans}
              />
            </div>
          </div>

          {!showOnlySignup && (
            <div styleName="flex-spaced-wrapper">
              <button styleName={`signup-btn ${!signIn ? "active" : ""}`} onClick={() => toggleSignInSignUp(false)}>
                SignUp
              </button>
              <button styleName={`signup-btn ${signIn ? "active" : ""}`} onClick={() => toggleSignInSignUp(true)}>
                SignIn
              </button>
            </div>
          )}
          <form onSubmit={handleSubmit} styleName={`${showOnlySignup ? "only-signup-form" : ""}`}>
            <div styleName={showOnlySignup ? "only-signup-box-wrapper" : !signIn ? "form-height" : ""}>
              <div styleName={`box-wrapper ${publisherLang === "ur" ? " qw-box-wrapper" : ""}`}>
                <div>
                  <EmailIcon />
                  <input
                    id="email"
                    name="email"
                    type="text"
                    styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}input-fields`}
                    placeholder="Email Address"
                    value={formvalues.email}
                    onChange={handleChange}
                  />
                </div>
                <small styleName="err-messages">{formErrors.email}</small>
              </div>
              {!showOnlySignup && (
                <div styleName={signIn ? "" : "two-col-fields"}>
                  <div
                    styleName={`${publisherLang === "ur" ? "qw-box-wrapper " : ""}${
                      signIn ? "box-wrapper" : "two-col-box-wrapper"
                    }`}
                  >
                    <div>
                      <KeyIcon />
                      <input
                        type="password"
                        name="password"
                        styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}${
                          signIn ? "input-fields" : "two-col-input-fields two-col-text-indent"
                        }`}
                        value={formvalues.password}
                        placeholder="Password"
                        onChange={handleChange}
                      />
                    </div>
                    <small styleName="err-messages">{formErrors.password}</small>
                  </div>

                  {!signIn && (
                    <div
                      styleName={`${publisherLang === "ur" ? "qw-box-wrapper " : ""}${
                        signIn ? "box-wrapper" : "two-col-box-wrapper"
                      }`}
                    >
                      <div>
                        <KeyIcon />
                        <input
                          type="password"
                          name="confirmPassword"
                          styleName={`${
                            publisherLang === "ur" ? "qw-input-field " : ""
                          }two-col-input-fields two-col-text-indent`}
                          value={formvalues.confirmPassword}
                          placeholder="Confirm Password"
                          onChange={handleChange}
                        />
                      </div>
                      <small styleName="err-messages">{formErrors.confirmPassword}</small>
                    </div>
                  )}
                </div>
              )}

              {showOnlySignup && (
                <div
                  styleName={`${publisherLang === "ur" ? "qw-box-wrapper " : ""}${
                    signIn ? "box-wrapper" : "two-col-box-wrapper"
                  }${showOnlySignup ? " signup-two-col-box-wrapper" : ""}`}
                >
                  <div>
                    <KeyIcon />
                    <input
                      type="password"
                      name="password"
                      styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}${
                        signIn ? "input-fields" : "two-col-input-fields two-col-text-indent"
                      }`}
                      value={formvalues.password}
                      placeholder="Password"
                      onChange={handleChange}
                    />
                  </div>
                  <small styleName="err-messages">{formErrors.password}</small>
                </div>
              )}

              {showOnlySignup &&
                !signIn && (
                  <div
                    styleName={`${publisherLang === "ur" ? "qw-box-wrapper " : ""}${
                      signIn ? "box-wrapper" : "two-col-box-wrapper"
                    }${showOnlySignup ? " signup-two-col-box-wrapper" : ""}`}
                  >
                    <div>
                      <KeyIcon />
                      <input
                        type="password"
                        name="confirmPassword"
                        styleName={`${
                          publisherLang === "ur" ? "qw-input-field " : ""
                        }two-col-input-fields two-col-text-indent`}
                        value={formvalues.confirmPassword}
                        placeholder="Confirm Password"
                        onChange={handleChange}
                      />
                    </div>
                    <small styleName="err-messages">{formErrors.confirmPassword}</small>
                  </div>
                )}
              {!signIn && (
                <div styleName={`box-wrapper${publisherLang === "ur" ? " qw-box-wrapper" : ""}`}>
                  <div>
                    <PersonIcon />
                    <input
                      type="text"
                      name="username"
                      placeholder="Full Name"
                      id="username"
                      styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}input-fields`}
                      value={formvalues.username}
                      onChange={handleChange}
                    />
                  </div>
                  <small styleName="err-messages">{formErrors.username}</small>
                </div>
              )}
              {!signIn && (
                <div styleName={`box-wrapper${publisherLang === "ur" ? " qw-box-wrapper" : ""}`}>
                  <div>
                    <PhoneIcon />
                    <input
                      type="number"
                      name="phone"
                      pattern="[\d]{10}"
                      styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}input-fields`}
                      placeholder="Mobile No"
                      id="phone"
                      value={formvalues.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <small styleName="err-messages">{formErrors.phone}</small>
                </div>
              )}
              {!showOnlySignup &&
                !signIn && (
                  <div styleName="two-col-fields">
                    <div styleName="half-width">
                      <select
                        styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}two-col-input-fields`}
                        name="gender"
                        value={formvalues.gender || ""}
                        onChange={handleChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      <small styleName="err-messages">{formErrors.gender}</small>
                    </div>
                    <div styleName="half-width">
                      <input
                        type={dateType}
                        placeholder="DOB"
                        onFocus={dateFocus}
                        styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}two-col-input-fields`}
                        name="dob"
                        value={formvalues.dob}
                        max={prevDay}
                        onChange={handleChange}
                      />
                      <small styleName="err-messages">{formErrors.dob}</small>
                    </div>
                  </div>
                )}
              {(!showOnlySignup || showAllFields) &&
                !signIn && (
                  <div styleName="box-wrapper">
                    <div>
                      <input
                        type="text"
                        name="address"
                        placeholder="Address"
                        id="address"
                        styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}two-col-input-fields`}
                        value={formvalues.address}
                        onChange={handleChange}
                      />
                    </div>
                    <small styleName="err-messages">{formErrors.address}</small>
                  </div>
                )}
              {!signIn && (
                <div styleName={showAllFields ? "signup-three-col-wrapper" : showOnlySignup ? "" : "two-col-fields"}>
                  <div styleName={`three-col-width${showOnlySignup ? " signup-three-col-width" : ""}`}>
                    <input
                      type="text"
                      name="city"
                      styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}two-col-input-fields`}
                      value={formvalues.city}
                      placeholder="City"
                      onChange={handleChange}
                    />
                    <small styleName="err-messages">{formErrors.city}</small>
                  </div>
                  {(!showOnlySignup || showAllFields) && (
                    <div styleName={`three-col-width${showAllFields ? " signup-three-col-width" : ""}`}>
                      <input
                        type="text"
                        name="state"
                        styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}two-col-input-fields`}
                        value={formvalues.state}
                        placeholder="State"
                        onChange={handleChange}
                      />
                      <small styleName="err-messages">{formErrors.state}</small>
                    </div>
                  )}
                  {(!showOnlySignup || showAllFields) && (
                    <div styleName={`three-col-width${showAllFields ? " signup-three-col-width" : ""}`}>
                      <input
                        type="text"
                        name="pincode"
                        styleName={`${publisherLang === "ur" ? "qw-input-field " : ""}two-col-input-fields`}
                        value={formvalues.pincode}
                        placeholder="Pincode"
                        onChange={handleChange}
                      />
                      <small styleName="err-messages">{formErrors.pincode}</small>
                    </div>
                  )}
                </div>
              )}
              {signIn && (
                <b styleName="forgot-pwd" onClick={onForgotPasswordClick}>
                  Forgot Password?
                </b>
              )}
            </div>
            {!signIn && (
              <div>
                <div styleName="checkbox-view">
                  <CheckBox isChecked={isTermsAccepted} onChange={termsChange} text="" />
                  <span styleName={publisherLang === "ur" ? "qw-checkbox-title " : "checkbox-title"}>
                    I accept all the{" "}
                    <a
                      styleName="highlight-text"
                      href={
                        changeTermsAndConditionURL
                          ? "/terms-conditions-digital-subs-international"
                          : "/terms-and-conditions"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Terms and Conditions{" "}
                    </a>
                    and
                    <a styleName="highlight-text" href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                      {" "}
                      Privacy Policy
                    </a>
                  </span>
                </div>
                <small styleName="err-messages">{formErrors.isTermsAccepted}</small>
              </div>
            )}
            {errorMsg && <p styleName="error-message">{errorMsg}</p>}
            <div styleName="box-wrapper">
              <input
                id={signIn ? "loginSubmit" : "submitButton"}
                type="submit"
                styleName="signup-action-btn"
                value={showOnlySignup ? "Confirm and pay" : signIn ? "Sign In" : "Sign Up"}
              />
            </div>
            {showOnlySignup && <span styleName="login-text-style">or Log in through</span>}
          </form>
        </div>
      )}
    </React.Fragment>
  );
};

LoginView.propTypes = {
  navigateToPage: func,
  closePopup: func,
  googleClientId: string,
  facebookId: number,
  setMember: func,
  setLoading: func,
  setUserPlans: func,
  publisherLang: string,
  makePayment: bool,
  razorPayPayment: func,
  showOnlySignup: bool,
  showAllFields: bool,
  changeTermsAndConditionURL: bool
};

const mapStateToProps = state => ({
  googleClientId: get(state, ["qt", "config", "publisher-attributes", "socialAuth", "google"], ""),
  facebookId: get(state, ["qt", "config", "publisher-attributes", "socialAuth", "fb"], ""),
  publisherLang: get(state, ["qt", "config", "publisher-attributes", "lang"], "")
});

const mapDispatchToProps = dispatch => ({
  navigateToPage: url => global.app.navigateToPage(dispatch, url),
  setMember: member => dispatch(setMember(member)),
  setLoading: isLoading => dispatch(setLoading(isLoading)),
  setUserPlans: list => dispatch(setUserPlans(list))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginView);
