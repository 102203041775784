import React from "react";
import { number } from "prop-types";

const YoutubeIcon = ({ width = 40, height = 40 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -77 512 512" width={width} height={height}>
    <defs />
    <path
      fill="red"
      d="M501 56c-5-22-23-39-45-45C416 0 256 0 256 0S96 0 56 11c-22 5-40 23-45 45C0 96 0 179 0 179s0 84 11 123c5 22 23 40 45 46 40 10 200 10 200 10s160 0 200-10c22-6 40-23 45-45 11-40 11-123 11-123s0-84-11-124zm0 0"
    />
    <path fill="#fff" d="M205 256l133-77-133-77zm0 0" />
  </svg>
);

YoutubeIcon.propTypes = {
  width: number,
  height: number
};

export default YoutubeIcon;
