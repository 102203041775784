// Action to enable/disable to loading indicator
export function setLoading(isLoading) {
  return isLoading ? { type: "QT-PAGE_LOADING" } : { type: "QT-PAGE_FINISHED_LOADING" };
}

export const setMember = member => {
  return {
    type: "QT-MEMBER_UPDATED",
    member
  };
};

export const setUserPlans = plans => {
  return {
    type: "SET_USER_PLANS",
    plans: plans && plans.length ? plans : []
  };
};

export const clearState = () => {
  return {
    type: "CLEAR_ALL"
  };
};
