import React from "react";
import PT from "prop-types";

import "./couplet.m.css";

function Couplet(props) {
  return (
    <div styleName="couplet-main-wrapper">
      {props.coupletData && (
        <div styleName="couplet-wrapper">
          {props.coupletData &&
            props.coupletData.map(function(item, index) {
              return (
                <div styleName="summary" key={index}>
                  {item}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
export { Couplet };

Couplet.propTypes = {
  coupletData: PT.array
};
