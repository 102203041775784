import React from "react";

import "./line-left-arrow.m.css";

const LineLeftArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" styleName="line-left-arrow">
    <path fill="transparent" d="M0 0h30v30H0z" data-name="Rectangle 1829" />
    <path d="M15 5l-1.762 1.762 6.975 6.988H5v2.5h15.212l-6.975 6.987L15 25l10-10z" data-name="Path 132909" />
  </svg>
);

export default LineLeftArrow;
