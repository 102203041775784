import React from "react";
import { node, string, func } from "prop-types";
import "./styles.m.css";

const Button = ({ children, className, onClick }) => (
  <button styleName="button" className={className} onClick={onClick}>
    {children}
  </button>
);

Button.propTypes = {
  children: node.isRequired,
  className: string,
  onClick: func
};

export { Button };
