import React from "react";
import PropTypes from "prop-types";
export function Linkedin({ color = "#333", width = 21, height = 21 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        id="a"
        d="M7.295 20.5h-3.53V9.154h3.53V20.5zM5.549 7.598a1.97 1.97 0 0 1-1.442-.607A1.97 1.97 0 0 1 3.5 5.55c0-.556.202-1.037.607-1.442A1.97 1.97 0 0 1 5.55 3.5a1.97 1.97 0 0 1 1.442.607c.405.405.607.886.607 1.442a1.97 1.97 0 0 1-.607 1.442 1.97 1.97 0 0 1-1.442.607zM20.5 20.5h-3.529v-5.54c0-.91-.076-1.569-.228-1.973-.278-.683-.822-1.025-1.631-1.025-.81 0-1.38.304-1.708.91-.253.456-.38 1.127-.38 2.012V20.5h-3.49V9.154h3.377v1.556h.038c.253-.506.657-.924 1.214-1.252.607-.405 1.315-.608 2.125-.608 1.644 0 2.795.519 3.453 1.556.506.835.759 2.125.759 3.87V20.5z"
      />
      <use fill={color} fillRule="evenodd" />
    </svg>
  );
}
Linkedin.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};
