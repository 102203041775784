import React ,{useEffect,useState}from "react";
import { bool, string, array } from "prop-types";
/* eslint-disable object-shorthand */
import { createDfpAdComponent, WithLazy } from "@quintype/components";
import { connect } from "react-redux";
import get from "lodash/get";

const ViewPortSizeMappingHorizontal728to320 = [
  { viewport: [980, 90], sizes: [[728, 90]] },
  { viewport: [320, 0], sizes: [[320, 50]] },
  { viewport: [0, 0], sizes: [[320, 50]] }
];

const ViewPortSizeMappingVertical600to250 = [
  { viewport: [980, 90], sizes: [[300, 600]] },
  { viewport: [320, 0], sizes: [[300, 250]] },
  { viewport: [0, 0], sizes: [[300, 250]] }
];

const ViewPortSizeMappingHorizontal728to300 = [
  { viewport: [980, 90], sizes: [[728, 90]] },
  { viewport: [300, 0], sizes: [[300, 250]] },
  { viewport: [0, 0], sizes: [[300, 250]] }
];

const CONFIG = {
  NH_HP_728x90: {
    adUnit: "NH_HP_728x90",
    sizes: [[728, 90], [320, 50]],
    viewPortSizeMapping: ViewPortSizeMappingHorizontal728to320
  },

  /* Newly Added */
  NH_Body: {
    adUnit: "NH_Body",
    sizes: [[728, 90], [300, 250]],
    viewPortSizeMapping: ViewPortSizeMappingHorizontal728to300
  },

  NH_E_Body: {
    adUnit: "NH_E_Body",
    sizes: [[728, 90], [300, 250]],
    viewPortSizeMapping: ViewPortSizeMappingHorizontal728to300
  },

  NH_Footer_Sticky: {
    adUnit: "NH_Footer_Sticky",
    sizes: [[728, 90], [320, 50]],
    viewPortSizeMapping: ViewPortSizeMappingHorizontal728to320
  },

  NH_Article_LHS_Sticky: { adUnit: "NH_Article_LHS_Sticky", sizes: [[160, 600]] }, // To target this only on desktop
  /* End */

  NH_300x600: {
    adUnit: "NH_300x600",
    sizes: [[300, 600], [300, 250]],
    viewPortSizeMapping: ViewPortSizeMappingVertical600to250
  },
  NH_300x250: { adUnit: "NH_300x250", sizes: [[300, 250]] },
  NH_A_Top: {
    adUnit: "NH_A_Top",
    sizes: [[300, 250], [336, 280], [400, 300]]
  },
  NH_MP_300x600: { adUnit: "NH_300x600", sizes: [[300, 600]] },

  Election_NH_HP_728x90: {
    adUnit: "Election_NH_HP_728x90",
    sizes: [[728, 90], [320, 50]],
    viewPortSizeMapping: ViewPortSizeMappingHorizontal728to320
  },

  Election_NH_HP_320X50: {
    adUnit: "Election_NH_HP_320X50",
    sizes: [[320, 50]]
  },

  Election_NH_HP_300X250: {
    adUnit: "Election_NH_HP_300X250",
    sizes: [[300, 250]]
  }
};

const getSectionBySlug = (slug, pageType) => {
  if (pageType === "story-page") {
    return slug.split("/")[1];
  }
  return slug.split("/")[2];
};

const DfpAdContent = createDfpAdComponent({
  defaultNetworkID: "21687414328",
  config: CONFIG,
  targeting: function(state) {
    const params = {
      publisher: get(state, ["qt", "config", "publisher-attributes", "dfp_target_value"]),
      pageType: get(state, ["qt", "pageType"]),
      env: get(state, ["qt", "config", "publisher-attributes", "env"])
    };
    if (
      get(state, ["qt", "pageType"]) === "story-page" &&
      get(state, ["qt", "data", "story", "metadata", "sponsored-by"])
    ) {
      params.sponsor = get(state, ["qt", "data", "story", "metadata", "sponsored-by"]);
      return params.sponsor;
    }
    if (get(state, ["qt", "pageType"]) === "section-page") {
      params.section =
        get(state, ["qt", "data", "section", "slug"]) || getSectionBySlug(get(state, ["qt", "currentPath"]));
    }

    if (get(state, ["qt", "pageType"]) === "story-page") {
      params.section =
        get(state, ["qt", "data", "story", "sections", 0, "slug"]) ||
        getSectionBySlug(get(state, ["qt", "currentPath"]), get(state, ["qt", "pageType"]));
      params.storyId = get(state, ["qt", "data", "story", "id"]);
    }
    return params;
  },
  disableInitialLoad: true,
  singleRequest: true
});

const DfpAdView = function(props) {
  const [digitalPlan,setDigitalPlan] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDigitalPlan(localStorage.getItem('activeDigitalSub'));
    }
  }, []);
  if (props.userPlans.filter(plan => plan.id === 2793).length >= 1) return null;
  if (digitalPlan && !(digitalPlan === "false")) return null;
  return (
    <WithLazy margin="300px">
      {() => (
        <div id="ad-wrapper">
          {props.text ? <span id="ad-text">ADVERTISEMENT</span> : null}
          <div id="ad-holder" data-size={`${props.width}x${props.height}`}>
            {props.pageType === "story-page" ? (
              props.storyId && <DfpAdContent {...props} />
            ) : (
              <DfpAdContent {...props} />
            )}
          </div>
        </div>
      )}
    </WithLazy>
  );
};

DfpAdView.propTypes = {
  isActivePlan: bool,
  height: string,
  width: string,
  text: bool,
  userPlans: array,
  storyId: string,
  pageType: string
};

function mapStateToProps(state) {
  return {
    isActivePlan: get(state, ["userReducer", "isActivePlan"], false),
    userPlans: get(state, ["userReducer", "userPlans"], [])
  };
}

export const DfpAd = connect(
  mapStateToProps,
  null
)(DfpAdView);
