import React from "react";
import PT from "prop-types";
import { Facebook } from "../icons/facebook";
import { Twitter } from "../icons/twitter";
import { Instagram } from "../icons/instagram";
import { Youtube } from "../icons/youtube";

import "./styles.m.css";
const SocialFollow = props => {
  const socialLinks = props.socialLinks || {};
  const socialComponentMap = {
    facebook: Facebook,
    twitter: Twitter,
    instagram: Instagram,
    youtube: Youtube
  };

  const socialComponents = Object.keys(socialLinks).map((socialLink, index) => {
    let componentName = "";
    let link = "#";
    if (socialLink.search("-") !== -1 && socialLinks[socialLink]) {
      let tempArray = socialLink.split("-");
      tempArray.pop();
      componentName = tempArray.join("-").toLowerCase();
      link = socialLinks[socialLink];
    }
    const SocialFollowComponent = socialComponentMap[componentName];

    const { width, height, backgroundColor, border, color, hasBackground } = props;

    return !SocialFollowComponent ? (
      SocialFollowComponent
    ) : (
      <li styleName="social-link" key={`social-component-${index}`}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <SocialFollowComponent
            width={width}
            height={height}
            color={color}
            hasBackground={hasBackground}
            backgroundColor={backgroundColor}
            border={border}
          />
        </a>
      </li>
    );
  });

  return (
    <ul styleName="social-follow" className={props.className}>
      {socialComponents}
    </ul>
  );
};

SocialFollow.propTypes = {
  socialLinks: PT.object,
  className: PT.string
};

export { SocialFollow };
