const userReducerState = {
  userPlans: [], // active/pending/cancelled can be anything
  userActivePlan: null,
  isActivePlan: false
};

const userReducer = (state = userReducerState, action) => {
  switch (action.type) {
    case "SET_USER_PLANS": {
      return {
        ...state,
        userPlans: action.plans.sort((a, b) => b.price_cents - a.price_cents),
        userActivePlan: action.plans.find(
          plan => (plan.status === "active" || plan.status === "pending") && !plan.cancelled
        ),
        isActivePlan: action.plans.some(plan => plan.active && !plan.cancelled)
      };
    }

    case "CLEAR_ALL": {
      return {
        ...state,
        userPlans: [],
        userActivePlan: [],
        isActivePlan: false
      };
    }

    default:
      return state;
  }
};

export default {
  userReducer
};
