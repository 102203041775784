import React from "react";
import { connect } from "react-redux";
import assetify from "@quintype/framework/assetify";
import PT from "prop-types";
import { Link } from "@quintype/components";

import NHlogo from "../../basic/images/nh.svg";
import NJlogo from "../../basic/images/nav.svg";
import QAlogo from "../../basic/images/qaumi.svg";
import get from "lodash/get";

import "./styles.m.css";

function AppLogoBase({ color, publisherAttributes = {}, hideDivider }) {
  return (
    <Link href="/" styleName="publisher-logo">
      {publisherAttributes.lang === "hi" ? (
        <img className="nj-logo" src={`${assetify(NJlogo)}`} alt="Navjivan" width="150px" height="37px" />
      ) : null}
      {publisherAttributes.lang === "ur" ? (
        <img src={`${assetify(QAlogo)}`} alt="Qaumi Awaz" width="130" height="32" />
      ) : null}
      {publisherAttributes.lang === "en" ? (
        <img className="nh-logo" src={`${assetify(NHlogo)}`} alt="National Herald" width="267" height="26" />
      ) : null}
      {hideDivider ? null : <div styleName="divider" />}
    </Link>
  );
}

AppLogoBase.propTypes = {
  color: PT.string,
  publisherAttributes: PT.object,
  hideDivider: PT.bool
};

function mapStateToProps(state) {
  return {
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"])
  };
}

export const AppLogo = connect(
  mapStateToProps,
  null
)(AppLogoBase);
