import React from "react";
import PT from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import "./styles.m.css";

function ArrowBase({ isOpen, publisherAttributes }) {
  const urArrow = publisherAttributes.lang === "ur" ? "ur-arrow" : "en-arrow";
  return (
    <div styleName={`arrow ${isOpen ? "arrow--open" : ""}`} className={urArrow}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="6" viewBox="0 0 14 6">
        <path fill="none" fillRule="nonzero" stroke="#979797" d="M1 6l6-6 6 6" />
      </svg>
    </div>
  );
}

ArrowBase.propTypes = {
  isOpen: PT.bool,
  publisherAttributes: PT.object
};

function mapStateToProps(state) {
  return {
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"])
  };
}

export const Arrow = connect(
  mapStateToProps,
  null
)(ArrowBase);
