import React from "react";
import PT from "prop-types";
import { SearchBox } from "@quintype/components";
import { connect } from "react-redux";
import get from "lodash/get";

import "./styles.m.css";

import { readCookie } from "../../utils/utils";

import { SearchIcon } from "../../basic/icons/search-icon";
import { CloseIcon } from "../../basic/icons/close-icon";
import { Button } from "../../basic/button/";
import VideoSectionLink from "../../atoms/video-section-link";

function DrawForm({ children }) {
  return [
    <label styleName="label" htmlFor="searchForm" key="1">
      {children}
    </label>,
    <Button type="submit" key="2" styleName="button">
      Search
    </Button>
  ];
}

class NavbarSearchBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchFormOpen: false,
      initialized: false,
      openMenu: false,
      selectedMenu: "",
      international: false
    };
    this.menuRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    const country = readCookie("country_code");
    if (country !== "IN" && country !== "undefined") {
      this.setState({
        international: true,
        initialized: true
      });
      if (
        pathname.includes("/subscribe")
      ) {
        this.props.navigateToPage("/");
      }
    } else {
      this.setState({
        initialized: true
      });
    }

    document.addEventListener("mousedown", this.handleClickOutside);
    if (pathname.includes("/subscribe")) {
      this.setState({ selectedMenu: "subscribe" });
    } else if (pathname.includes("/subscription")) {
      this.setState({ selectedMenu: "subscription" });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.state.openMenu) {
      if (this.menuRef && !this.menuRef.current.contains(event.target)) {
        this.setState(Object.assign(this.state, { openMenu: false }));
      }
    }
  }

  subscribeClick = path => {
    this.setState(Object.assign(this.state, { openMenu: false }));
    this.setState(Object.assign(this.state, { selectedMenu: path }));
    this.props.navigateToPage(`/${path}`);
  };

  toggleSearchForm() {
    this.setState({ isSearchFormOpen: !this.state.isSearchFormOpen }, () => {
      document.getElementById("searchForm").focus();
    });
  }

  render() {
    const formStyle = {
      transform: this.state.isSearchFormOpen ? "rotateX(0deg)" : "rotateX(90deg)"
    };
    const PublisherLang = this.props.publisherAttributes.lang;
    return (
      <div styleName="search" className={this.props.className}>
        <div styleName="subscribe-style">
          <button type="button" styleName="subscriptions-btn">
            <a
              onClick={() => {
                this.setState(Object.assign(this.state, { openMenu: true }));
              }}
            >
              Subscription
            </a>
          </button>
          {this.state.openMenu && (
            <div ref={this.menuRef} styleName={`subscribe-menu ${PublisherLang === "ur" ? "subscribe-menu-ur" : ""}`}>
              {!this.state.international && (
                <a
                  styleName={`${
                    this.state.selectedMenu === "subscribe" ? "selected-menu-item" : ""
                  } image-subscribe-menu-item`}
                  onClick={() => {
                    this.subscribeClick("subscribe");
                  }}
                >
                  <img src="https://images.assettype.com/nationalherald/2022-12/7e1a8ece-58bc-41da-8221-47fee01e037e/National_Herald_On_Sunday.jpeg" />
                  <img src="https://images.assettype.com/navjivanindia/2022-12/c58df2e1-eac2-48e3-a376-901f3fe09eb0/Sunday_Navjivan.jpeg" />
                </a>
              )}
              <a
                styleName={`${
                  this.state.selectedMenu === "subscription" && PublisherLang === "en" ? "selected-menu-item" : ""
                } subscribe-menu-item ${PublisherLang === "ur" ? "subscribe-menu-item-ur" : ""}`}
                target="_blank"
                href="https://www.nationalheraldindia.com/subscription"
                rel="noreferrer"
              >
                Nationalheraldindia.com
              </a>
              <a
                styleName={`${
                  this.state.selectedMenu === "subscription" && PublisherLang === "hi" ? "selected-menu-item" : ""
                } subscribe-menu-item ${PublisherLang === "ur" ? "subscribe-menu-item-ur" : ""}`}
                target="_blank"
                href="https://www.navjivanindia.com/subscription"
                rel="noreferrer"
              >
                Navjivanindia.com
              </a>
              <a
                styleName={`${
                  this.state.selectedMenu === "subscription" && PublisherLang === "ur" ? "selected-menu-item" : ""
                } subscribe-menu-item ${PublisherLang === "ur" ? "subscribe-menu-item-ur" : ""}`}
                target="_blank"
                href="https://www.qaumiawaz.com/subscription"
                rel="noreferrer"
              >
                Qaumiawaz.com
              </a>
            </div>
          )}
        </div>

        <VideoSectionLink />
        <button styleName="search__btn" onClick={() => this.toggleSearchForm()}>
          <SearchIcon color={this.props.searchIconColor || "#fff"} />
        </button>
        <div styleName="search__form" style={formStyle}>
          <div styleName="search__form-container">
            <SearchBox
              styleName="search__box"
              template={DrawForm}
              inputId="searchForm"
              inputRef={input => (this.input = input)}
              inputClassName="input"
              formRef={searchForm => (this.searchForm = searchForm)}
              onSubmitHandler={() => this.setState({ isSearchFormOpen: false })}
              onEscape={() => this.setState({ isSearchFormOpen: false })}
              placeholder="Search for headline, topics, authors etc"
            />
            <div styleName="close-icon" onClick={() => this.toggleSearchForm()}>
              <CloseIcon />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NavbarSearchBase.propTypes = {
  searchIconColor: PT.string,
  className: PT.string,
  publisherAttributes: PT.object,
  navigateToPage: PT.func
};

const mapDispatchToProps = dispatch => ({
  navigateToPage: url => global.app.navigateToPage(dispatch, url)
});
function mapStateToProps(state) {
  return {
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"], [])
  };
}
export const NavbarSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarSearchBase);
