import React from "react";
import "./styles.m.css";
import PT from "prop-types";
function HamburgerIcon({ onClick }) {
  return (
    <div styleName="hamburger-icon" onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
        <g fill="#000" fillRule="nonzero">
          <path d="M0 0h16v2H0zM0 6h16v2H0zM0 12h16v2H0z" />
        </g>
      </svg>
    </div>
  );
}

HamburgerIcon.propTypes = {
  onClick: PT.func
};
export { HamburgerIcon };
