import React from "react";

function PersonIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="5 8 24 24">
      <g>
        <g opacity="1">
          <g opacity=".5">
            <path fill="#6c6c6c" d="M5 15.5C5 9.701 9.925 5 16 5s11 4.701 11 10.5S22.075 26 16 26 5 21.3 5 15.5z" />
          </g>
          <g opacity="1">
            <path
              fill="#f5f5f5"
              d="M13.807 14.984h3.017a5.591 5.591 0 0 1 5.526 4.99H8.268a5.591 5.591
              0 0 1 5.54-4.98zm1.509-7.476a3.54 3.54 0 1 1 0 7.08 3.54 3.54 0 0 1
              0-7.08zM.687 30h29.257V.743H.687z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PersonIcon;
