import React from "react";
import PT from "prop-types";
export function CloseIcon({
  color = "#fff",
  width = 18,
  height = 18,
  onClick
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11 12"
      onClick={onClick}
    >
      <g fill="none" fillRule="nonzero">
        <path d="M-6-6h24v24H-6z" />
        <g stroke={color} strokeLinecap="square" strokeWidth="2">
          <path d="M9.39 2.11L1.61 9.89M9.39 9.89L1.61 2.11" />
        </g>
      </g>
    </svg>
  );
}

CloseIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number,
  onClick: PT.func
};
