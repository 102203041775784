import { get } from "lodash";

export const getStoryWithConfig = state => {
  return {
    story: get(state, ["qt", "data", "story"], {}),
    config: get(state, ["qt", "config"], {})
  };
};

export const removeHTMLTags = str => {
  return (str && str.replace(/<\/?[^>]+(>|$)/g, "")) || null;
};

export const getArticleBodyFromStory = story => {
  let accumulator = "";

  story.cards.forEach(card => {
    card["story-elements"].forEach(storyElement => {
      if (storyElement.type === "text") {
        accumulator += removeHTMLTags(storyElement.text);
      }
    });
  });

  return accumulator;
};

export const readCookie = key => {
  const findkey = `${key}=`;
  const cookies = document.cookie.split(";");
  const value = cookies.find(key => key.includes(findkey));
  return value.replace(findkey, "").trim();
};
